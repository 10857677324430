import React from 'react';
import "./footer.css";

export const Footer = () => {
  return (
      <footer className='footer'>
      Copyright &#169; 2023 Prentice Ahmad. All rights reserved. Created by Sweetest Interactive AdsMedia
      </footer>
  )
}
